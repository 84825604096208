import React from "react"
import Layout from "../components/layout"
import classnames from "classnames"
import SEO from "../components/seo"
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

import style from "./travel.module.css"

const loadingGif = require(`../assets/loading-large.gif`)

const Travel = props => {
	const importAll = data => {
		let images = {}
		data.keys().map((item, index) => {
			return (images[item.replace("./", "")] = data(item))
		})
		return images
	}

	const getFiltered = array => {
		return Object.fromEntries(
			Object.entries(array).filter(([key, value]) => {
				return value.includes("thumb")
			})
		)
	}

	const images = importAll(
		require.context(
			"../assets/travel/compressed/",
			false,
			/\.(png|jpe?g|svg)$/
		)
	)

	const titles = [
		"New York",
		"Cyprus",
		"Zurich",
		"Amsterdam",
		"Cyprus",
		"London",
		"New York",
		"Zurich",
		"Cyprus",
		"Amsterdam",
		"Zurich",
		"Miami",
		"Zurich",
		"New York",
		"Cyprus",
		"Prague",
		"Cyprus",
		"Cyprus",
		"New York",
		"Zurich",
		"London",
		"Miami",
		"New York",
		"New York",
		"London",
		"Cyprus",
		"Cyprus",
		"New York",
		"Amsterdam",
		"New York",
		"New York",
		"New York",
		"New York",
		"San Francisco",
		"New York",
		"Zurich",
		"New York"
	]

	const filteredImages = getFiltered(images)

	return (
		<Layout>
			<SEO
				title="Travel Photography"
				description="Travel and Landscape Photography including Post Production and Editing from Various Locations."
				keywords="Karpasitis, Andreas, Travel, Landscape, Photo, Photography, Photographer, Barcelona, New York, Zurich, Cyprus"
				url="https://www.karpasitis.net/travel"
			/>
			<div className={style.container}>
				<div className={style.container}>
					<header>
						<h1 className={style.title}>Travel Photography</h1>
					</header>
					<div className={style.photos} id="photos">
						{Object.keys(filteredImages).map((image, index) => {
							const imageIndex = index + 1
							const imageNumber =
								imageIndex < 10 ? "0" + imageIndex : imageIndex

							const imageSrc = require(`../assets/travel/compressed/${imageNumber}-thumb.jpg`)

              return (
								<div key={`${image}`} className={style.link}>
									<img
										className={classnames('lazyload', style.imageStyle)}
										key={`${image}-img`}
                    alt={`${titles[index]}`}
                    src={loadingGif}
                    data-src={imageSrc}
                    data-sizes="auto"
									/>
									<div className={style.label}>
										{titles[index]}
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Travel
